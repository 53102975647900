<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M503.172,114.759H392.828c-2.438,0-4.414,1.976-4.414,4.414v3.915
	c0,9.506-7.306,17.686-16.801,18.134c-10.138,0.478-18.509-7.602-18.509-17.635v-4.414c0-2.438-1.976-4.414-4.414-4.414H8.828
	c-4.875,0-8.828,3.953-8.828,8.828v264.828c0,4.875,3.953,8.828,8.828,8.828H348.69c2.438,0,4.414-1.976,4.414-4.414v-3.915
	c0-9.506,7.306-17.686,16.801-18.134c10.138-0.478,18.509,7.602,18.509,17.635v4.414c0,2.438,1.976,4.414,4.414,4.414h110.345
	c4.875,0,8.828-3.953,8.828-8.828V123.586C512,118.711,508.047,114.759,503.172,114.759z M379.586,344.276
	c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828v-17.655c0-4.875,3.948-8.828,8.828-8.828
	c4.879,0,8.828,3.953,8.828,8.828V344.276z M379.586,291.31c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828
	v-17.655c0-4.875,3.948-8.828,8.828-8.828c4.879,0,8.828,3.953,8.828,8.828V291.31z M379.586,238.345
	c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828V220.69c0-4.875,3.948-8.828,8.828-8.828
	c4.879,0,8.828,3.953,8.828,8.828V238.345z M379.586,185.379c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828
	v-17.655c0-4.875,3.948-8.828,8.828-8.828c4.879,0,8.828,3.953,8.828,8.828V185.379z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M97.103,346.483c-2.828,0-5.646-1.078-7.801-3.233c-4.31-4.31-4.31-11.293,0-15.604l158.897-158.897
		c4.31-4.31,11.293-4.31,15.604,0c4.311,4.31,4.31,11.293,0,15.604L104.906,343.25C102.75,345.405,99.932,346.483,97.103,346.483z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M123.586,249.379c-25.551,0-46.345-20.789-46.345-46.345s20.793-46.345,46.345-46.345
		s46.345,20.789,46.345,46.345S149.138,249.379,123.586,249.379z M123.586,178.759c-13.388,0-24.276,10.888-24.276,24.276
		c0,13.388,10.888,24.276,24.276,24.276s24.276-10.888,24.276-24.276C147.862,189.646,136.974,178.759,123.586,178.759z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M229.517,355.31c-25.551,0-46.345-20.789-46.345-46.345s20.793-46.345,46.345-46.345
		s46.345,20.789,46.345,46.345S255.069,355.31,229.517,355.31z M229.517,284.69c-13.388,0-24.276,10.888-24.276,24.276
		s10.888,24.276,24.276,24.276s24.276-10.888,24.276-24.276S242.905,284.69,229.517,284.69z"
      />
    </g>
    <path
      style="fill:#FFC850;"
      d="M379.586,167.724v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c4.879,0,8.828,3.953,8.828,8.828
	v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c4.879,0,8.828,3.953,8.828,8.828v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655
	c4.879,0,8.828,3.953,8.828,8.828v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c9.75,0,17.655,7.905,17.655,17.655v4.414
	c0,2.438,1.976,4.414,4.414,4.414h110.345c4.875,0,8.828-3.953,8.828-8.828V123.586c0-4.875-3.953-8.828-8.828-8.828H392.828
	c-2.438,0-4.414,1.976-4.414,4.414v4.414c0,9.75-7.905,17.655-17.655,17.655v17.655
	C375.638,158.897,379.586,162.849,379.586,167.724z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
